<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('nectios-languages') }}</h4>
      <b-button variant="primary" class="ml-1" @click="submit()">
        {{ $t('form.actions.save') }}
      </b-button>
    </div>
    <b-row class="d-flex flex-wrap">
      <b-col>
        <b-form-group>
          <label for="default" class="font-weight-bold"> {{ $t("preferred-language") }}</label>
          <locale :display-mode="'select'" :has-label="false" @newLocale="getNewLocale" />
        </b-form-group>
      </b-col>
      <b-col>
        <!-- TimeZone -->
        <b-form-group>
          <label for="time-zone" class="font-weight-bold"> {{ $t('backoffice.settings.time-zone') }}</label>
          <v-select
            id="time-zone"
            v-model="form.timeZone"
            :searchable="false"
            :placeholder="$t('form.select-placeholder')"
            :options="timeZoneOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import Service from '@/config/service-identifiers';
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue';

export default {
  name: 'Languages',
  components: {
    vSelect,
    Locale,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    currentMember: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        language: '',
        timeZone: '',
      },
      timeZoneOptionsFull: {},
      timeZoneOptions: [],
      newLocale: null,
    };
  },
  computed: {
    languagesOptions() {
      return [
        { code: 'es', language: this.$t('languages-options.spanish') },
        { code: 'ca', language: this.$t('languages-options.catalan') },
        { code: 'en', language: this.$t('languages-options.english') },
        { code: 'bg', language: this.$t('languages-options.bulgarian') },
        { code: 'da', language: this.$t('languages-options.danish') },
      ];
    },
  },
  watch: {
    currentMember(newValue) {
      this.form.language = this.languagesOptions.filter((i) => i.code === newValue.language);
      this.form.timeZone = Object.values(this.timeZoneOptions).filter((i) => i === newValue.timezone)[0];
    },
  },
  async created() {
    this.form.language = this.languagesOptions.filter((i) => i.code === this.currentMember.language);
    await this.getTimezones();
    this.form.timeZone = Object.values(this.timeZoneOptions).filter((i) => i === this.currentMember.timezone)[0];
  },
  methods: {
    getNewLocale(newLocale) {
      this.newLocale = newLocale;
    },
    async submit() {
      const requestConfig = {
        timezone: parseInt(
          Object.entries(this.timeZoneOptionsFull).find((item) => item[1] === this.form.timeZone)[0],
          10,
        ),
        languageCode: this.form.language.code,
        userKey: this.currentMember.key,
      };
      try {
        await this.$store.$service[Service.BackendClient].put('user', requestConfig);
        if (this.newLocale) {
          this.$store.dispatch('setLocale', this.newLocale);
          this.$store.dispatch('updateUserLocale', { languageCode: this.newLocale });
          //TODO: review why doing this..
          setTimeout(async () => {
            await this.fetchLoggedUser()
          }, 800)
        }
        // this.$store.dispatch('setLocale', this.form.language.code);
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
      } catch {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
    async getTimezones() {
      const response = await this.$store.dispatch('getTimezones');
      this.timeZoneOptionsFull = response;
      this.timeZoneOptions = Object.values(response);
    },
    async fetchLoggedUser() {
      await this.$store.dispatch('fetchLoggedUser');
    },
  },
};
</script>
