<template>
  <div>
    <div>
      <b-card class="mt-2">
        <!-- Seetings form -->
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <h4>{{ $t('edit-dropdown.details') }}</h4>
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSaving"
            @click="updateProfile"
          >
            {{ $t('form.actions.save') }}
          </b-button>
        </div>
        <!-- Body -->
        <!-- Form -->
        <b-form class="pt-2">
          <!-- Name -->
          <b-row class="match-height">
            <b-col lg="6">
              <validation-provider
                #default="validationContext"
                :name="$t('members.edit.name.name')"
                rules="required|max:255"
              >
                <b-form-group
                  :label="$t('members.edit.name.label')"
                  label-for="profile-name"
                  class="font-weight-bold"
                >
                  <b-form-input
                    id="profile-name"
                    v-model="profile.name"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('form.type-placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Surname -->
              <validation-provider
                #default="validationContext"
                :name="$t('members.edit.surname.name')"
                rules="required|max:255"
              >
                <b-form-group
                  :label="$t('members.edit.surname.label')"
                  label-for="profile-surname"
                  class="font-weight-bold"
                >
                  <b-form-input
                    id="profile-surname"
                    v-model="profile.surname"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('form.type-placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Headline -->
              <validation-provider
                #default="validationContext"
                :name="$t('members.edit.headline.name')"
                rules="max:255"
              >
                <b-form-group>
                  <label for="profile-headline" class="font-weight-bold">
                    {{ $t('members.edit.headline.label') }}
                    <b-button
                      v-if="!individualChat"
                      v-b-tooltip.hover.right
                      :title="$t('members.edit.headline.tooltip')"
                      variant="link"
                      class="p-0 link-button"
                      @click="isInputDescriptionModalVisible = true"
                    >
                      <feather-icon icon="InfoIcon" size="16" />
                    </b-button>
                  </label>
                  <description-input
                    v-model="isInputDescriptionModalVisible"
                    :title="$t('members.edit.headline.label')"
                    :description="$t('members.edit.headline.tooltip')"
                  />

                  <b-form-input
                    id="profile-headline"
                    v-model="profile.headline"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('form.type-placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Username -->
              <validation-provider
                #default="validationContext"
                :name="$t('members.edit.surname.name')"
                rules="required|max:255"
              >
                <b-form-group
                  :label="`${$t('sign-up.form.username.label')}*`"
                  label-for="profile-surname"
                  class="font-weight-bold"
                >
                  <b-form-input
                    id="profile-surname"
                    v-model="profile.username"
                    :state="availableSlug"
                    trim
                    :placeholder="$t('form.type-placeholder')"
                    :disabled="isSaving"
                    @input="getUsernameValidator(validationContext)"
                  />

                  <b-form-invalid-feedback v-if="!availableSlug" class="text-danger">
                    {{ usernameMessage }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Email -->
              <b-form-group
                :label="`${$t('backoffice.settings.email-label')}*`"
                label-for="profile-surname"
                class="font-weight-bold"
              >
                <b-form-input
                  id="email-name"
                  v-model="profile.email"
                  type="email"
                  :state="availableEmail"
                  readonly
                  @input="getEmailValidator()"
                  :placeholder="$t('form.type-placeholder')"
                />
                <b-form-invalid-feedback v-if="!availableEmail" class="text-danger">
                    {{ emailMessage }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <!-- Summary -->
              <validation-provider #default="validationContext" :name="$t('members.edit.summary.name')">
                <b-form-group
                  :label="$t('members.edit.summary.label')"
                  label-for="profile-summary"
                  class="font-weight-bold"
                >
                  <plain-editor
                    id="profile-summary"
                    v-model="profile.summary"
                    class="form-control text-editor"
                    style="height: 180px"
                    :placeholder="$t('members.edit.summary.placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Birth -->
              <b-form-group>
                <label for="startDate" class="font-weight-bold"> {{ $t('birth') }} </label>
                <b-form-datepicker
                  v-model="profile.birth"
                  class="mt-50"
                  :locale="locale"
                />
              </b-form-group>
              <!-- Gender -->
              <label for="logo" class="font-weight-bold"> {{ $t('gender') }}</label>
              <v-select
                v-model="profile.gender"
                class="select-control p-0"
                clearable
                :options="valueOptions"
                :get-option-label="(a) => a.gender"
                :placeholder="$t('form.select-placeholder')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" class="mt-1 mt-lg-0">
              <!-- Avatar -->
              <b-form-group>
                <label for="logo" class="font-weight-bold">
                  {{ $t('members.edit.avatar.label') }}
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    :title="`${$t('tooltips.recommended')}
                    100px x 100px`"
                    icon="InfoIcon"
                    size="14"
                    class="text-primary mt-n25"
                  />
                </label>
                <br>
                <b-media-body>
                  <div>
                    <b-form-file
                      v-model="avatar"
                      accept=".jpg, .png, .gif"
                      class="pointer"
                      :placeholder="$t('form-create-item.image-placeholder')"
                      :browse-text="$t('form-create-item.image-button')"
                    />
                  </div>
                </b-media-body>
                <div class="d-block mx-auto" style="position: relative; width: 160px">
                  <b-img
                    ref="refPreviewEl"
                    :src="profile.avatarURL || logoPlaceholder"
                    width="80"
                    class="rounded my-1  mb-md-0 avatar"
                    style="background: #c0c0c0; min-height: 100px; min-width: 150px; max-height: 300px;"
                  />
                  <feather-icon
                    v-if="profile.avatarURL"
                    icon="XCircleIcon"
                    size="18"
                    class="text-primary mx-auto close-icon"
                    @click="removeImg(profile.avatarURL)"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <!-- Background -->
              <b-form-group>
                <label for="logo" class="font-weight-bold">
                  {{ $t('members.edit.background.label') }}
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    :title="`${$t('tooltips.recommended')} 650px x 150px`"
                    icon="InfoIcon"
                    size="14"
                    class="text-primary mt-n25"
                  />
                </label>
                <br>
                <b-media no-body vertical-align="bottom" class="d-flex flex-wrap align-items-center">
                  <b-media-body>
                    <div>
                      <b-form-file
                        v-model="background"
                        accept=".jpg, .png, .gif"
                        class="pointer"
                        :placeholder="$t('form-create-item.image-placeholder')"
                        :browse-text="$t('form-create-item.image-button')"
                      />
                    </div>
                    <div class="d-block mx-auto" style="position: relative; width: 310px">
                      <b-img
                        ref="refPreviewEl"
                        :src="profile.backgroundURL || bannerPlaceholder"
                        width="300"
                        class="rounded my-1 mb-md-0"
                        style="background: #c0c0c0; min-height: 100px; max-width: 300px; max-height: 350px;"
                      />
                      <feather-icon
                        v-if="profile.backgroundURL"
                        icon="XCircleIcon"
                        size="18"
                        class="text-primary d-block  mx-auto close-icon"
                        @click="removeImg(profile.backgroundURL)"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Service from '@/config/service-identifiers';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import DescriptionInput from '@core/components/layouts/apps-layout/DescriptionInput.vue';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import { validatorEmailValidator, userNameValidator } from '@/@core/utils/validations/validators';
import vSelect from "vue-select";

export default {
  name: 'GeneralSettings',
  props: {
    currentMember: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    DescriptionInput,
    PlainEditor,
    vSelect,
  },
  directives: { BTooltip: VBTooltip },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      member: null,
      form: {
        password: '',
        newPassword: '',
        newPassword2: '',
      },
      required,
      max,
      isStaff: false,
      send: false,
      profile: {
        avatarURL: null,
        backgroundURL: null,
        name: '',
        surname: '',
        headline: '',
        summary: '',
        username: '',
        gender: '',
        birth: '',
        timezone: '',
        email: '',
      },
      avatar: null,
      background: null,
      isSaving: false,
      isAddOrganizationModalVisible: false,
      isInputDescriptionModalVisible: false,
      individualChat: false,
      isLoading: true,
      isValid: null,
      index: '',
      availableSlug: null,
      availableEmail: null,
      currentUsername: '',
      currentEmail: '',
      emailMessage: '',
      usernameMessage: '',
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    valueOptions() {
      return [
        { code: 1, gender: this.$t('gender-options.male')},
        { code: 2, gender: this.$t('gender-options.female')},
        { code: 3, gender: this.$t('gender-options.other')},
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    bannerPlaceholder() {
      return BannerPlaceholder;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
  },
  watch: {
    currentMember(newValue) {
      this.profile = newValue;
      this.profile.birth = new Date(newValue.birthday);
      this.profile.gender = this.valueOptions.filter(i=> i.code === (newValue.gender?.code || newValue.gender ))[0];
      this.currentUsername = newValue.username;
      this.currentEmail = newValue.email;
    },
    background(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.profile.backgroundURL = value;
            })
            .catch(() => {
              this.profile.backgroundURL = null;
            });
        } else {
          this.profile.backgroundURL = null;
        }
      }
    },
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.profile.avatarURL = value;
            })
            .catch(() => {
              this.profile.avatarURL = null;
            });
        } else {
          this.profile.avatarURL = null;
        }
      }
    },
  },
  created() {
    this.profile = this.currentMember;
    this.profile.birth = new Date(this.currentMember.birthday);
    this.profile.gender = this.valueOptions.filter(i=> i.code === (this.currentMember.gender?.code || this.currentMember.gender))[0];
    this.currentEmail = this.currentMember.email;
    this.currentUsername = this.currentMember.username;
  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resetForm() {
      this.avatar = null;
      this.background = null;
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
    },
    async updateProfile() {
      const requestConfig = {
        userKey: this.currentMember.key,
        name: this.profile.name,
        surname: this.profile.surname,
        summary: this.profile.summary,
        gender: this.profile?.gender?.code,
        ...(this.profile.username === this.currentUsername ? '' : { username: this.profile.username }),
        birth: this.profile.birth,
        // ...(this.profile.email === this.currentEmail ? '' : { email: this.profile.email }),
        headline: this.profile.headline === null ? '' : this.profile.headline,
        deleteBanner: !this.profile.backgroundURL ? 1 : 0,
        deleteProfile: !this.profile.avatarURL ? 1 : 0,
      };
      let response;
      if (this.avatar || this.background) {
        const formData = new FormData();
        if (this.avatar) {
          formData.append('profileURL', this.avatar);
        }
        if (this.background) {
          formData.append('backgroundURL', this.background);
        }
        formData.append('data', JSON.stringify(requestConfig));

        response = await this.$store.$service[Service.BackendClient].put('user', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
      } else {
        response = await this.$store.$service[Service.BackendClient].put('user', requestConfig);
      }
      if (Object.values(response?.data)?.length > 0) {
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
      } else {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
    communityLocation(event) {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        event.subdomain || 'app',
      ).replace('{slug}', event.slug);
    },
    async onSubmit() {
      try {
        await this.updateProfile();
        this.notifySuccess(this.$t('members.edit.success-message'));
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    removeImg(item) {
      if (item === this.profile.avatarURL) {
        this.profile.avatarURL = null;
        this.avatar = null;
      } else {
        this.profile.backgroundURL = null;
        this.background = null;
      }
    },
    handleSaveLinks(links) {
      this.profile.links = links;
      this.onSubmit();
    },
    async getUsernameValidator() {
      if (!userNameValidator(this.profile.username)) {
        this.availableSlug = false;
        this.usernameMessage = this.$t('settings.error-message');
        return;
      }
      try {
        await this.$store.$service[Service.BackendClient].get('user/checkUsername', {
          params: {
            username: this.profile.username,
          },
        });
        this.availableSlug = true;
      } catch (e) {
        this.availableSlug = this.profile.username === this.currentUsername;
        this.usernameMessage = this.$t('username-message');
      }
    },
    async getEmailValidator() {
      if (!validatorEmailValidator(this.profile.email) || this.profile.email === '') {
        this.availableEmail = false;
        this.emailMessage = '';
        return;
      }
      try {
        await this.$store.$service[Service.BackendClient].get('user/checkEmail', {
          params: {
            email: this.profile.email,
          },
        });
        this.availableEmail = true;
      } catch (e) {
        this.availableEmail = this.profile.email === this.currentEmail;
        this.emailMessage = this.$t('email-message');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.foto {
  max-height: 150px;
  max-width: 150px;
}
.banner {
  max-height: 200px;
  max-width: 500px;
}
.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.text-edit {
  height: 5rem !important;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
</style>
