<template>
  <main-container>
    <div :class="{'mt-n2' : !isSmall}">
      <!-- Seetings form -->
      <general-settings :current-member="loggedUser"/>
      <languages :current-member="loggedUser" />
      <b-card>
        <validation-observer v-if="!send" #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="d-flex flex-wrap justify-content-between my-2">
              <h4>{{ $t('newPassword.title') }}</h4>
              <b-button variant="primary" type="submit">
                {{ $t('form.actions.save') }}
              </b-button>
            </div>
            <b-row>
              <b-col lg="6">
                <!-- Actual password -->
                <validation-provider #default="validationContext" name=" " rules="required">
                  <b-form-group>
                    <label for="actualPassw" class="font-weight-bold">
                      {{ $t('newPassword.form.password.label') }}*</label>
                    <b-form-input
                      id="actualPassw"
                      v-model="form.password"
                      type="password"
                      :placeholder="$t('form.type-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="6">
                <b-form>
                  <label for="s" class="font-weight-bold">
                    {{ $t('newPassword.knowledge') }}
                  </label>
                </b-form>
                <b-button :href="helpLocation" target="_blank" variant="outline-primary">
                  {{ $t('backoffice.dashboard.help-center') }}
                </b-button>
              </b-col>
            </b-row>
                <!-- New password -->
                <b-row>
                  <b-col cols="6">
                  <validation-provider #default="validationContext" name=" " rules="required|max:255">
                    <b-form-group>
                      <label for="newPassw" class="font-weight-bold"> {{ $t('newPassword.form.password2.label') }}*</label>
                      <b-form-input
                        id="newPassw"
                        v-model="form.newPassword"
                        type="password"
                        :placeholder="$t('form.type-placeholder')"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback class="text-danger">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  </b-col>
                  <b-col cols="6">
                  <!-- Repeat New password -->
                  <validation-provider #default="validationContext" name=" " rules="required|max:255">
                    <b-form-group>
                      <label for="newPassw2" class="font-weight-bold"> {{ $t('newPassword.form.password3.label') }}*</label>
                      <b-form-input
                        id="newPassw2"
                        v-model="form.newPassword2"
                        type="password"
                        :placeholder="$t('form.type-placeholder')"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback class="text-danger">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  </b-col>
                </b-row>
              
          </b-form>
        </validation-observer>
        <div v-else>
          <b-alert variant="success" show>
            {{ $t('newPassword.form.password-success') }}
          </b-alert>
        </div>
      </b-card>
      <!-- Danger Zone -->
      <danger-zone :userkey="loggedUser.key" />
    </div>
  </main-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContainer from "@/my-nectios/components/MainContainer.vue";
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import GeneralSettings from '@/my-nectios/components/GeneralSettings.vue';
import DangerZone from '@/my-nectios/components/DangerZone.vue';
import Languages from '@/my-nectios/components/Languages.vue';
import Service from '@/config/service-identifiers';
import Banner from '@/assets/images/placeholders/light/Header_My_Left.svg';
import callToAction from '@/assets/images/placeholders/light/Header_My_Right.svg';

export default {
  name: 'Settings',

  components: {
    ValidationProvider,
    ValidationObserver,
    GeneralSettings,
    DangerZone,
    Languages,
    MainContainer,
  },

  mixins: [ToastNotificationsMixin],

  data() {
    return {
      form: {
        password: '',
        newPassword: '',
        newPassword2: '',
      },
      required,
      max,
      isStaff: false,
      send: false,
      isSmall: false,
      isMedium: false,
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },

  computed: {
    ...mapGetters(['loggedUser']),

    mySpacesLocation() {
      return {
        name: 'my-communities',
      };
    },
    bannerStyle() {
      return {
        'background-image': `url(${this.banner})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        ...(this.isSmall ? { 'background-position': 'bottom' } : {'background-position': 'right'}),
        'border-radius': '1.2rem',
        'margin-right': '10px',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': "center",
      };
    },
    callStyle() {
      return {
        'background-image': `url(${this.callToActionImage})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'border-radius': '1.2rem',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': "center",

      };
    },
    banner() {
      return Banner;
    },
    callToActionImage() {
      return callToAction;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },

    username() {
      return this.$route.params.username;
    },
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    helpLocation() {
      return process.env.VUE_APP_HELP_CENTER;
    },
    // currentMember() {
    //   return this.$store.getters.loggedUser;
    // },
  },
  // watch: {
  //   currentMember(value) {
  //     return value;
  //   },
  // },

  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    // if (!this.loggedUser.id) {
    //   await this.fetchLoggedUser2();
    // }
    this.setLocale(this.loggedUser?.language);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    // ...mapActions(['fetchLoggedUser2']),

    handleResize() {
      this.isSmall = window.innerWidth < 400;
      this.isMedium = window.innerWidth < 1024;
    },
    setLocale(language) {
      this.$store.dispatch('setLocale', language);
    },

    async onSubmit() {
      const requestConfig = {
        oldPassword: this.form.password,
        password: this.form.newPassword,
        repeatPassword: this.form.newPassword2,
        userKey: this.loggedUser.key,
      }
      ;
      try {
        const response = await this.$store.$service[Service.BackendClient].post(
          "user/changePassword",
          {
            ...requestConfig,
          }
        );
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
        return response;
      } catch (error) {
        this.notifyError(this.$t('newPassword.form.same-old-password'));
      }
    },

    // async fetchLoggedUser() {
    //   await this.$store.dispatch('fetchLoggedUser');
    // },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
